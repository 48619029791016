import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom';
import DocumentTitle from "react-document-title";
import LoginScreenLayout from "../uxkit/LoginScreenLayout";
import Input from "../uxkit/Input";
import Alert from "../uxkit/Alert";
import {BASE_URL, processServerResponse} from "../utils";
import axios from "axios";

class ForgetPassword extends Component {
    constructor(props){
        super(props);

        this.state = {
            emailAddress: '',
            errorMsg: null,
            loading: false,
            submittedOk: null,
        }
    }

    handleChange = (event) => {
        let fieldName = event.target.name;
        let fieldValue = event.target.value;

        this.setState({
            [fieldName]: fieldValue,
            errorMsg:null,
        })
    };


    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
        }
    };

    handleSubmit = async () => {
        const {emailAddress} = this.state;

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const valid = re.test(emailAddress);

        if(!valid){
            this.setState({errorMsg: <span>The email is invalid.</span>});
            return;
        }

        try {
            this.setState({loading: true});

            const payload = {
                username: emailAddress,
            };
            const axiosResult = await axios.post("/requestPasswordReset", payload, {headers: {'Content-Type': 'application/json'}});
            this.setState(processServerResponse(axiosResult))
        } catch (error) {
            const status = error.response.status;
            let errorMsg = <span>Server returned ${status} code.</span>;

            this.setState({errorMsg})
        } finally {
            this.setState({loading: false});
        }
    };


    render() {
        const {emailAddress, errorMsg, submittedOk, loading} = this.state;
        const disableBtn = emailAddress.length === 0 || !!submittedOk || loading;

        return (
            <DocumentTitle title="Reset Your Password">
                <section className="page">
                    <LoginScreenLayout>
                        <h1>Reset your password</h1>

                        {!!errorMsg &&
                            <Alert type={"error"}>
                                {errorMsg}
                            </Alert>
                        }

                        {!!submittedOk &&
                        <Alert type={"success"}>
                            Instructions on how to set the new password have been sent to your email.
                        </Alert>
                        }

                        <Input
                            label="Email Address"
                            type="email"
                            id="emailAddress"
                            placeholder="student@example.com"
                            aria-labelledby="emailAddressLabel"
                            aria-required={true}
                            required={true}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            disabled={submittedOk}
                        />

                        {!submittedOk &&
                        <Alert type={'info'}>Email address input is case sensitive.</Alert>
                        }

                        <button disabled={disableBtn}
                                className={`btn-submit btn-center ${loading? 'btn-loading' : ''}`}
                                onClick={this.handleSubmit}>
                            Reset Password
                        </button>

                        <p className="text-center">
                            <Link to={BASE_URL}>
                                Remember your password? Then it's time to login!
                            </Link>
                        </p>

                    </LoginScreenLayout>
                </section>
            </DocumentTitle>
        )
    }
}

export default withRouter(ForgetPassword)