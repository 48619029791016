import React from 'react';
import PropTypes from 'prop-types';

const getClassName = (type) => {
    switch(type){
        case "success":
            return "ui success message";
        case "error":
            return "ui error message";
        case "warn":
            return "ui warn message";
        case "info":
            return "ui info message";
        default:
            return "";
    }
};

const Alert = (props) => {
    const {type} = props;
    const className = getClassName(type);

    return (
        <div
            className={`${className} alert`}
            role="alert"
            data-component="alert"
            aria-live={(type === 'error' || type === 'warn') ? 'assertive' : 'polite'}
        >
            {props.children}
        </div>
    )
};

Alert.propTypes = {
    type: PropTypes.oneOf(['error','success','warn', 'info']),
};

export default Alert