import React, {Component} from 'react'
import {withRouter} from 'react-router-dom';
import DocumentTitle from "react-document-title";
import LoginScreenLayout from "../uxkit/LoginScreenLayout";
import Input from "../uxkit/Input";
import axios from "axios";
import {BASE_URL, processServerResponse} from "../utils";
import Alert from "../uxkit/Alert";

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        const {location: {search}, history} = props;
        const params = new URLSearchParams(search);
        const token = params.get('reset_password_token');
        const user = params.get('user');
        if (token === null || user === null) {
            history.replace(BASE_URL);
        }

        this.state = {
            resetToken: token,
            userName: user,
            password: '',
            passwordConfirmation: '',
            loading: false,
            submittedOk: false,
            errorMsg: null,
        }
    }

    handleChange = (event) => {
        let fieldName = event.target.name;
        let fieldValue = event.target.value;

        this.setState({
            [fieldName]: fieldValue,
            errorMsg: null,
        })
    };


    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit()
        }
    };

    isStrongPassword = (password) => {
        const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()_+=?]).{8,}/;

        if (password.length < 8) {
            this.setState({errorMsg: <span>Your password must be at least 8 characters in length.</span>});
            return false
        }

        if (!passwordRegex.test(password)) {
            this.setState({errorMsg: <span>Your password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.</span>});
            return false
        }

        return true
    };

    handleSubmit = async () => {
        const {resetToken, password, passwordConfirmation, userName} = this.state;

        if (password !== passwordConfirmation) {
            this.setState({
                errorMsg: <span>Password and password confirmation fields must match.</span>,
            });
            return;
        }

        if (this.isStrongPassword(password)) {
            try {
                this.setState({loading: true});

                const payload = {userName, resetToken, password};
                const axiosResult = await axios.post("/resetPassword", payload, {headers: {'Content-Type': 'application/json'}});
                this.setState(processServerResponse(axiosResult))
            } catch (error) {
                const status = error.response.status;
                let errorMsg = <span>Server returned {status} code.</span>;

                this.setState({errorMsg})
            } finally {
                this.setState({loading: false});
            }
        }
    };

    render() {
        const {password, passwordConfirmation, loading, submittedOk, errorMsg} = this.state;
        const disableBtn = password === '' || passwordConfirmation === '' || !!submittedOk || loading;

        return (
            <DocumentTitle title="Set Your New Password">
                <section className="page">
                    <LoginScreenLayout>
                        <h1>Set your new password</h1>

                        {!!errorMsg &&
                        <Alert type={"error"}>
                            {errorMsg}
                        </Alert>
                        }

                        {!!submittedOk &&
                        <Alert type={"success"}>
                            Your password was set successfully.
                        </Alert>
                        }

                        <Input
                            label="Password"
                            type="password"
                            id="password"
                            placeholder="Enter your new password"
                            aria-labelledby="passwordLabel"
                            aria-required={true}
                            required={true}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                        />

                        <Input
                            label="Password confirmation"
                            type="password"
                            id="passwordConfirmation"
                            placeholder="Enter the new password again"
                            aria-labelledby="passwordLabel"
                            aria-required={true}
                            required={true}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                        />

                        <button disabled={disableBtn}
                                className={`btn-submit btn-center ${loading ? 'btn-loading' : ''}`}
                                onClick={this.handleSubmit}>
                            Set New Password
                        </button>
                    </LoginScreenLayout>
                </section>
            </DocumentTitle>
        )
    }
}

export default withRouter(ResetPassword)