import React from 'react';
import './App.css';
import Login from "./pages/login";
import ForgetPassword from "./pages/forgetPassword";
import ResetPassword from "./pages/resetPassword";

import axios from 'axios';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {datadogRum} from "@datadog/browser-rum";
import '@trilogyeducation/bcs-style-library/public/scss/main.scss';

import {BASE_URL} from "./utils";
import RequestId from "./components/requestId";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requestId: '',
            otChanged: false
        }
    }

    componentWillMount() {
        this.initApp();
    }


    initApp = () => {
        window.addEventListener('otChanged', () => {
            let newOtChanged = window.localStorage.getItem("otChanged");
            this.setState({
                otChanged: newOtChanged
            });
            this.loadDD(newOtChanged);
        })
    }

    performanceCookiesAllowed = otChanged =>
        otChanged && decodeURIComponent(document.cookie.split("; ").find((row) => row.startsWith("OptanonConsent="))?.split("groups=")[1]).includes('C0002:1');

    loadDD = async (otChanged) => {
        if (otChanged && this.performanceCookiesAllowed(otChanged)) {
            try {
                const response = await axios.post("/prepareApp", {headers: {'Content-Type': 'application/json'}});
                if (response.data) {
                    if (response.data.dataDogRumConfig) {
                        datadogRum.init(response.data.dataDogRumConfig);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
    }




    setRequestId = (newRequestId) => {
        const {requestId} = this.state;
        if (requestId === '') {
            this.setState({
                requestId: newRequestId
            });
        }
    };

    render() {
        const {requestId} = this.state;

        return (
            <BrowserRouter>
                <RequestId setRequestId={this.setRequestId}>
                    <Switch>
                        <Route exact path={"/"}
                               render={() => <Login requestId={requestId}/>}/>

                        <Route exact path={`${BASE_URL}`}
                               render={() => <Login requestId={requestId}/>}/>

                        <Route path={`${BASE_URL}/forget-password`}
                               render={() => <ForgetPassword requestId={requestId}/>}/>

                        <Route path={`${BASE_URL}/reset-password`}
                               render={() => <ResetPassword requestId={requestId}/>}/>

                        <Route path={`${BASE_URL}/privacy-policy`}
                               render={() => {window.location.replace("https://www.bootcampspot.com/privacy-policy")}}/>

                        <Route path={`${BASE_URL}/terms-and-conditions`}
                               render={() => {window.location.replace("https://www.bootcampspot.com/terms-and-conditions")}}/>
                    </Switch>
                </RequestId>
            </BrowserRouter>
        )
    };
}

export default App;
