import React, {Component} from 'react'
import DocumentTitle from 'react-document-title';
import {Link, withRouter} from 'react-router-dom';
import axios from "axios";
import {Form} from "@trilogyeducation/forms";

import Input from "../uxkit/Input";
import Alert from "../uxkit/Alert";
import LoginScreenLayout from "../uxkit/LoginScreenLayout";
import {BASE_URL} from "../utils";

const NO_SURVEY_SCHEDULE_AVAILABLE = "NO_SURVEY_SCHEDULE_AVAILABLE";
const SURVEY_ALREADY_SUBMITTED = "SURVEY_ALREADY_SUBMITTED";
const SURVEY_UNSUPPORTED = 'SURVEY_UNSUPPORTED';

const errorCodesToContinueFormNavigation = [
    NO_SURVEY_SCHEDULE_AVAILABLE,
    SURVEY_ALREADY_SUBMITTED,
    SURVEY_UNSUPPORTED,
];

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authToken: null,
            samlCallbackUrl: null,
            relayStateValue: '',
            samlResponse: '',
            loading: false,
            errorMsg: null,
            forms: []
        };

        this.hiddenForm = React.createRef();
    }

    componentDidMount() {
        this.setState({
            authToken: null,
            samlCallbackUrl: null,
            relayStateValue: '',
            samlResponse: '',
            loading: false,
            errorMsg: null,
            forms: []
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {navigatingToCanvasDashboard} = this.state;
        if (!!navigatingToCanvasDashboard) {
            this.hiddenForm.current.submit();
        }
    }

    handleChange = (event) => {
        let fieldName = event.target.name;
        let fieldValue = event.target.value;

        this.setState({
            [fieldName]: fieldValue,
            errorMsg: null,
        })
    };

    handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit().then();
        }
    };

    handleSubmit = async () => {
        const {emailAddress, password} = this.state;

        try {
            this.setState({loading: true});

            const axiosResult = await axios.post("/login", {username: emailAddress, password}, {headers: {'Content-Type': 'application/json'}});
            if (!!axiosResult) {
                const status = axiosResult.status;
                if (status === 200) {
                    const {token} = axiosResult.data;

                    const forms = await this.handleGetForms(token);
                    
                    if (forms && forms.length > 0) {
                        this.setState({
                            authToken: token,
                            forms: forms,
                            loading: false
                        });
                    } else {
                        await this.handleCanvasLogin(token);
                    }
                } else {
                    this.setState({errorMsg: `Please try again, if the problem persists contact Support.`, loading: false});
                }
            } else {
                this.setState({errorMsg: `Please try again, if the problem persists contact Support.`, loading: false});
            }
        } catch (error) {
            const status = error.response && error.response.status;
            let errorMsg = `Please try again, if the problem persists contact Support.`;
            if (status === 401) {
                errorMsg = `Your email address or password is incorrect.`;
            }
            this.setState({errorMsg, loading: false});
        }
    };

    handleCanvasLogin = async (authToken) => {
        try {
            const axiosResult = await axios.post("/canvasLogin", {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'authToken': authToken,
                }
            });
            if (!!axiosResult) {
                const status = axiosResult.status;
                if (status === 200) {
                    const xmlData = new DOMParser().parseFromString(axiosResult.data, 'application/xml');
                    const form = xmlData.getElementById('samlpost');
                    const samlResponse = xmlData.getElementsByName('SAMLResponse')[0];
                    const relayStateValue = xmlData.getElementsByName('RelayState')[0];
                    const url = form.action;
                    this.setState({
                        samlCallbackUrl: url,
                        samlResponse: samlResponse.defaultValue,
                        relayStateValue: relayStateValue.defaultValue,
                        navigatingToCanvasDashboard: true
                    });
                } else {
                    this.setState({errorMsg: `Please try again, if the problem persists contact Support.`});
                }
            } else {
                this.setState({errorMsg: `Please try again, if the problem persists contact Support.`});
            }
        } catch (error) {
            const status = error.response && error.response.status;
            let errorMsg = `Please try again, if the problem persists contact Support.`;
            if (status === 401) {
                errorMsg = `Your email address or password is incorrect.`;
            }
            this.setState({errorMsg});
        }
    };

    handleGetForms = async (authToken) => {
        try {
            const axiosResult = await axios.post("/listSurveyToFire", {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'authToken': authToken,
                }
            });

            if (!!axiosResult) {
                const status = axiosResult.status;
                if (status === 200) {
                    return axiosResult.data.data;
                } else {
                    console.error(`Please try again, if the problem persists contact Support.`);
                }
            } else {
                console.error(`Please try again, if the problem persists contact Support.`);
            }
        } catch (error) {
            console.error({errorMsg: `Please try again, if the problem persists contact Support.`});
        }
    };

    handleSubmitForm = async (data) => {
        const {authToken, forms} = this.state;

        try {
            await this.setState({loading: true});

            const axiosResult = await axios.post("/submitSurvey", data, {
                headers: {
                    'Content-Type': 'application/json',
                    'authToken': authToken,
                }
            });

            const status = axiosResult.status;

            if (status) {
                if (status === 200) {
                    forms.shift();
                    if (forms.length > 0) {
                        this.setState({
                            forms: forms,
                            loading: false
                        });
                    } else {
                        await this.handleCanvasLogin(authToken);
                    }
                } else {
                    this.setState({errorMsg: `Please try submitting again, if the problem persists contact Support.`, loading: false});
                }
            } else {
                this.setState({errorMsg: `Please try submitting again, if the problem persists contact Support.`, loading: false})
            }
        } catch (error) {
            const errorData = error.response && error.response.data && error.response.data.slice(0, -1);
            const isIgnorableError = errorCodesToContinueFormNavigation.find(e => e.includes(errorData));
            if(!isIgnorableError){
                this.setState({errorMsg: `Please try submitting again, if the problem persists contact Support.`, loading: false});
            } else {
                forms.shift();
                if (forms.length > 0) {
                    this.setState({
                        forms: forms,
                        loading: false
                    });
                } else {
                    await this.handleCanvasLogin(authToken);
                }
            }
        }
    };

    handleSnoozeForms = async () => {
        const {authToken, forms} = this.state;

        try {
            this.setState({loading: true});

            const data = {
                surveysToSnoozeData: forms
            }

            await axios.post("/snoozeSurvey", data, {
                headers: {
                    'Content-Type': 'application/json',
                    'authToken': authToken,
                }
            });
            await this.handleCanvasLogin(authToken);
        } catch (error) {
            await this.handleCanvasLogin(authToken);
        }
    };

    render() {
        const {
            authToken,
            samlCallbackUrl,
            relayStateValue,
            samlResponse,
            errorMsg,
            loading,
            forms,
            navigatingToCanvasDashboard
        } = this.state;

        const disableSignInButton = loading;

        let content;

        if(!authToken && !navigatingToCanvasDashboard) {
            content = <DocumentTitle title="Sign In to Your Account">
                <section className="page" data-component="login">
                    <LoginScreenLayout>
                        <h1>Sign in to your account</h1>
                        {!!errorMsg? (
                          <Alert type="error"
                                 onDismiss={() => this.setState({errorMsg: null})}>
                              <span>{errorMsg}</span>
                          </Alert>
                        ) : null}

                        <Input
                          label="Email Address"
                          type="email"
                          id="emailAddress"
                          name="emailAddress"
                          placeholder="student@example.com"
                          aria-labelledby="emailAddressLabel"
                          aria-required={true}
                          required={true}
                          onChange={this.handleChange}
                          onKeyPress={this.handleKeyPress}
                        />

                        <Alert type={'info'}>Email address input is case sensitive.</Alert>

                        <Input
                          label="Password"
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Enter your password"
                          aria-labelledby="passwordLabel"
                          aria-required={true}
                          required={true}
                          onChange={this.handleChange}
                          onKeyPress={this.handleKeyPress}
                        />

                        <button id="loginSubmit"
                                name="loginSubmit"
                                disabled={disableSignInButton}
                                className={`btn-submit btn-center ${disableSignInButton ? 'ui loading button' : ''} `}
                                onClick={this.handleSubmit}>
                            Sign In
                        </button>

                        <p className="text-center">
                            <Link to={`${BASE_URL}/forget-password`}>Forgot your password?</Link>
                        </p>


                        <p className="text-center">
                            <Link to={`${BASE_URL}/privacy-policy`}>Privacy Policy</Link>
                            &nbsp;&nbsp;
                            <Link to={`${BASE_URL}/terms-and-conditions`}>Terms and Conditions</Link>
                        </p>
                        <p className="text-center">
                            If you have trouble logging in, try resetting your password or {' '}
                            <a href="https://codingbootcamp.secure.force.com/onesupport/">get in touch with us through this
                                form.</a>
                        </p>
                    </LoginScreenLayout>
                </section>
            </DocumentTitle>;
        } else if(forms.length > 0 && !navigatingToCanvasDashboard) {
            content = <Form
              loading={loading}
              error={errorMsg}
              data={forms[0]}
              isLastSurvey={forms.length === 1}
              submitButtonText={forms.length > 1 ? `Submit and Go to Next Survey` : `Submit`}
              snoozable={forms[0].snoozable}
              onSubmit={this.handleSubmitForm}
              onSnooze={this.handleSnoozeForms}
            />;
        }

        return (
            <div>
                <form ref={this.hiddenForm} method="POST" action={samlCallbackUrl}>
                    <input type={"hidden"} name={"RelayState"} value={relayStateValue}/>
                    <input type={"hidden"} name={"SAMLResponse"} value={samlResponse}/>
                </form>
                {
                    navigatingToCanvasDashboard &&
                    <div className="ui">
                        <div className="ui active inverted dimmer">
                            <div className="ui large text loader">Loading</div>
                        </div>
                    </div>
                }
                {content}
            </div>
        )
    }
}

export default withRouter(Login)
