import React from 'react';
import {Link} from "react-router-dom";

export const BASE_URL = '/ui';

export const translateServerError = (errorCode) => {
    switch(errorCode){
        case "INVALID_USERACCOUNT":
        case "INVALID_USERNAME":
            return <span>The specified email address isn't recognized.</span>;
        case "INVALID_TOKEN":
            return <span>
                The token is no longer valid.<br/>
                Please create a new password reset request <Link to={`${BASE_URL}/forget-password`}>here</Link>.
            </span>;
        default:
            return <span>The server responded with {errorCode}.</span>;
    }
};

export const processServerResponse = (axiosResult) => {
    let newState = {};
    if (!!axiosResult) {
        const {status, data} = axiosResult;
        if (status === 200) {
            if (!data.success) {
                let errorMsg = translateServerError(data.errorCode);
                newState = {errorMsg}
            } else {
                newState = {
                    submittedOk: true,
                    errorMsg: null,
                };
            }
        } else {
            newState = {errorMsg: <span>Server returned ${status} code.</span>};
        }
    } else {
        newState = {errorMsg: <span>An unknown error occurred.</span>};
    }

    return newState;
};