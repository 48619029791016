import React from 'react';

const LoginScreenLayout = (props) => (
    <div data-component="login-screen-layout" className="row max-width-none">
        <div className="col-sm-3 col-lg-4 hide-xs show-sm bcs-login-background min-height-100"/>
        <div className="col-xs-12 col-sm-9 col-md-6 col-lg-4 padding-6 min-height-100" role="application">
            {props.children}
        </div>
    </div>
);

export default LoginScreenLayout
