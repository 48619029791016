import {Component} from 'react'
import {withRouter} from 'react-router-dom';

class RequestId extends Component {
    componentDidMount() {
        const {location:{search}, setRequestId} = this.props;
        const params = new URLSearchParams(search);
        const requestId = params.get('requestId');
        if (requestId !== null) {
            setRequestId(requestId)
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(RequestId)